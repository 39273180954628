import styled from 'styled-components';
import { rem } from 'polished';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: ${rem(3)};
  border: ${rem(1)} solid ${({ theme }) => theme.colors.backgroundLight};
  padding: ${({ theme }) => theme.spacing.md};
  margin: ${({ theme }) => `${theme.spacing.md} 0`};
  max-width: ${rem(679)};
  width: 100%;
  ${({ theme }) => theme.media.tablet} {
    padding: ${({ theme }) => `${theme.spacing.lg} ${theme.spacing.xl}`};
    margin: ${({ theme }) => `${theme.spacing.xl} 0`};
  }
`;

export const SelectWrapper = styled.div`
  width: ${rem(150)};
  margin-left: ${({ theme }) => theme.spacing.sm};
  li {
    &:hover {
      color: ${({ theme }) => theme.colors.bodyText};
    }
  }
  ul {
    max-height: ${rem(150)};
    overflow: auto;
  }
`;
