import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@strikelabs/luna';
import { createEvent } from '@strikelabs/sputnik';

import {
  ProgressBar,
  AnswerContainer,
  AnswerButton,
  BackButton,
} from 'components/Pages/CostOfMoving';

const NoOfBedrooms = ({ answers, setAnswers, navigation }) => {
  const handleBack = () => {
    createEvent({
      category: 'Cost of moving calculator',
      action: 'Back button click',
      label: `From number of bedrooms to ${
        answers.isBuying ? 'buyer type' : 'selling price'
      }`,
    });
    if (answers.isBuying) {
      if (answers.isFirstTimeBuyer) {
        setAnswers({
          ...answers,
          isFirstTimeBuyer: false,
          isNotFirstTimeBuyer: false,
        });
        navigation.go('buyerType1');
      } else {
        setAnswers({
          ...answers,
          isOnlyHome: false,
          hasMoreThanOneHome: false,
        });
        navigation.go('buyerType2');
      }
    } else {
      navigation.go('sellingPrice');
    }
  };

  const handleClick = numberOfBedrooms => {
    setAnswers(prev => ({ ...prev, numberOfBedrooms }));
    createEvent({
      category: 'Cost of moving calculator',
      action: 'Number of bedrooms answer',
      label: `Number of bedrooms: ${numberOfBedrooms}`,
    });
    navigation.go('moveType');
  };

  return (
    <>
      <ProgressBar progress={answers.isBuying ? 70 : 60} />
      <Text>
        Removal costs depend on the size of your home. How many bedrooms does
        your current property have?
      </Text>
      <AnswerContainer noIcon>
        <AnswerButton onClick={() => handleClick(1)} noIcon>
          <Text styleType="h1">1</Text>
        </AnswerButton>
        <AnswerButton onClick={() => handleClick(2)} noIcon>
          <Text styleType="h1">2</Text>
        </AnswerButton>
        <AnswerButton onClick={() => handleClick(3)} noIcon>
          <Text styleType="h1">3</Text>
        </AnswerButton>
        <AnswerButton onClick={() => handleClick(4)} noIcon>
          <Text styleType="h1">4</Text>
        </AnswerButton>
        <AnswerButton onClick={() => handleClick(5)} noIcon>
          <Text styleType="h1">5+</Text>
        </AnswerButton>
      </AnswerContainer>
      <BackButton styleType="link" onClick={() => handleBack()}>
        Back
      </BackButton>
    </>
  );
};

NoOfBedrooms.propTypes = {
  answers: PropTypes.shape({
    isSelling: PropTypes.bool,
    isBuying: PropTypes.bool,
    isFirstTimeBuyer: PropTypes.bool,
    isNotFirstTimeBuyer: PropTypes.bool,
    isOnlyHome: PropTypes.bool,
    hasMoreThanOneHome: PropTypes.bool,
    moveType: PropTypes.string,
    sellingPrice: PropTypes.number,
    buyingPrice: PropTypes.number,
    numberOfBedrooms: PropTypes.number,
    agentComission: PropTypes.number,
    depositSize: PropTypes.number,
  }),
  setAnswers: PropTypes.func,
  navigation: PropTypes.object,
};

export default NoOfBedrooms;
