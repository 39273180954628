import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Text, Box, Flex, Select, Button } from '@strikelabs/luna';
import { formatCurrency } from '@strikelabs/pax';
import { createEvent } from '@strikelabs/sputnik';

import { Container, SelectWrapper } from '../Incrementor';
import { ProgressBar, BackButton } from 'components/Pages/CostOfMoving';
import Slider from 'components/Slider';

const createIncrementsBetween = ({ from, to, increment }) => {
  let numbers = [];
  for (let i = from; i < to; i += increment) {
    numbers.push(i);
  }
  return numbers;
};

const range = [
  { from: 10000, to: 100000, increment: 5000 },
  { from: 100000, to: 1000000, increment: 10000 },
  { from: 1000000, to: 3000000, increment: 50000 },
  { from: 3000000, to: 5100000, increment: 100000 },
];

const getCommissionRates = () => {
  const rates = createIncrementsBetween({
    from: 0,
    to: 0.05,
    increment: 0.005,
  });
  return rates.map(rate => ({
    value: rate,
    label: `${(rate * 100).toFixed(1)}%`,
  }));
};

const SellingPrice = ({ answers, setAnswers, navigation }) => {
  const [valueCallback, setValueCallback] = useState(230000);
  const [commissionRate, setCommissionRate] = useState(0.015);
  const handleBack = () => {
    setAnswers({ ...answers, isSelling: false, isBuying: false });
    createEvent({
      category: 'Cost of moving calculator',
      action: 'Back button click',
      label: 'From selling price to transaction type',
    });
    navigation.go('transactionType');
  };

  const handleSumbit = () => {
    setAnswers({
      ...answers,
      sellingPrice: valueCallback,
      agentCommission: valueCallback * commissionRate,
    });
    createEvent({
      category: 'Cost of moving calculator',
      action: 'Selling price answer',
      label: `Selling price: ${formatCurrency(valueCallback)}`,
    });
    /* If user is not buying we skip the buying questions */
    if (!answers.isBuying) {
      navigation.go('noOfBedrooms');
    } else {
      navigation.go('buyingPrice');
    }
  };
  return (
    <>
      <ProgressBar progress={answers.isBuying ? 28 : 40} />
      <Text>Great. Tell us about the property you&apos;re selling.</Text>
      <Flex justifyContent="center">
        <Container>
          <Box>
            <Text>Selling price</Text>
          </Box>
          <Flex justifyContent="center" mt={[2, 2, 3]}>
            <Text as="h2">{formatCurrency(valueCallback)}</Text>
          </Flex>
          <Box mt={4} mb={2}>
            <Slider
              increments={range}
              setValueCallback={setValueCallback}
              initialSliderPos={0.185}
            />
          </Box>
          <Flex alignItems="center" justifyContent="center" mt={[0, 0, 3]}>
            <Text ext>Estate agent commission</Text>
            <SelectWrapper>
              <Select
                value={commissionRate}
                placeholder="Rate..."
                onChange={rate => setCommissionRate(rate)}
                options={getCommissionRates()}
              />
            </SelectWrapper>
          </Flex>
          <Text styleType="caption" mt={4}>
            We&apos;ve estimated traditional estate agents at 1.5%, but they can
            vary. If you&apos;re using Strike, it&apos;s always 0%.{' '}
          </Text>
        </Container>
      </Flex>
      <Flex mb={2} width={1} justifyContent="center">
        <Button onClick={() => handleSumbit()}>Next</Button>
      </Flex>

      <BackButton styleType="link" onClick={() => handleBack()} belowNext>
        Back
      </BackButton>
    </>
  );
};

SellingPrice.propTypes = {
  answers: PropTypes.shape({
    isSelling: PropTypes.bool,
    isBuying: PropTypes.bool,
    isFirstTimeBuyer: PropTypes.bool,
    isNotFirstTimeBuyer: PropTypes.bool,
    isOnlyHome: PropTypes.bool,
    hasMoreThanOneHome: PropTypes.bool,
    moveType: PropTypes.string,
    sellingPrice: PropTypes.number,
    buyingPrice: PropTypes.number,
    numberOfBedrooms: PropTypes.number,
    agentComission: PropTypes.number,
    depositSize: PropTypes.number,
  }),
  setAnswers: PropTypes.func,
  navigation: PropTypes.object,
};

export default SellingPrice;
