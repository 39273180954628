import styled from 'styled-components';
import { Button, Flex } from '@strikelabs/luna';
import { rem } from 'polished';

export const OuterButton = styled(Button)`
  padding: ${({ theme }) => `${theme.spacing.md} ${theme.spacing.md}`};
  margin: ${({ theme }) => `${theme.spacing.xs} 0`};
  border-radius: ${rem(3)};
  width: ${({ noIcon }) => (noIcon ? rem(98) : `100%`)};
  max-width: ${rem(500)};
  white-space: normal !important;

  & > span {
    padding: 0;
    width: 100%;
  }

  & > span > span {
    width: 100%;
  }

  ${({ theme }) => theme.media.smallTablet} {
    width: ${({ noIcon }) => (noIcon ? rem(200) : `100%`)};
  }

  ${({ theme }) => theme.media.tablet} {
    margin: ${({ theme }) => theme.spacing.sm};
    width: ${({ noIcon }) => (noIcon ? rem(240) : `100%`)};
    max-width: ${rem(311)};
    padding-top: ${({ theme }) => theme.spacing.xs};
    padding-bottom: ${({ theme }) => theme.spacing.xs};
  }

  ${({ theme }) => theme.media.desktop} {
    width: ${({ noIcon }) => (noIcon ? rem(300) : `100%`)};
  }

  ${({ theme }) => theme.media.giant} {
    width: ${({ noIcon }) => (noIcon ? rem(311) : `100%`)};
  }
`;

export const InnerButton = styled(Flex)`
  align-items: center;
  justify-content: ${({ noIcon }) => (noIcon ? `center` : `space-around`)};

  ${({ theme }) => theme.media.tablet} {
    min-height: ${({ noIcon }) => (noIcon ? `auto` : rem(436))};
    flex-direction: column-reverse;
    padding: ${({ theme }) => `${theme.spacing.lg}`};
  }
`;
