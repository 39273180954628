import React from 'react';
import PropTypes from 'prop-types';
import { Text, ShowBreakpoint, HideBreakpoint } from '@strikelabs/luna';
import { createEvent } from '@strikelabs/sputnik';

import {
  ProgressBar,
  AnswerContainer,
  AnswerButton,
  BackButton,
} from 'components/Pages/CostOfMoving';

const MoveType = ({ answers, setAnswers, navigation }) => {
  const handleBack = () => {
    createEvent({
      category: 'Cost of moving calculator',
      action: 'Back button click',
      label: `From move type to ${
        answers.isSelling ? 'number of bedrooms' : 'buyer type'
      }`,
    });
    if (answers.isSelling) {
      navigation.go('noOfBedrooms');
    } else if (answers.isBuying) {
      if (!answers.isFirstTimeBuyer) {
        navigation.go('buyerType2');
      } else {
        navigation.go('buyerType1');
      }
    }
  };

  const handleClick = moveType => {
    setAnswers(prev => ({ ...prev, moveType }));
    createEvent({
      category: 'Cost of moving calculator',
      action: 'Move type answer',
      label: `Move type: ${moveType}`,
    });
    navigation.go('result');
  };

  return (
    <>
      <ProgressBar progress={answers.isBuying ? 84 : 80} />
      <Text>How are you planning on moving?</Text>
      <AnswerContainer>
        <AnswerButton onClick={() => handleClick('diy')} icon="Diy">
          <HideBreakpoint breakpoint="smallTablet">
            Doing it all by myself
            <br />
            &mdash; and bribing some
            <br />
            friends with pizza
          </HideBreakpoint>
          <ShowBreakpoint breakpoint="smallTablet">
            Doing it all myself &mdash; and bribing some friends with pizza
          </ShowBreakpoint>
        </AnswerButton>
        <AnswerButton onClick={() => handleClick('van')} icon="Van">
          <HideBreakpoint breakpoint="smallTablet">
            I&apos;ll do the packing,
            <br />
            but I&apos;m hiring a van and
            <br />
            mover to deal with
            <br />
            the heavy lifting
          </HideBreakpoint>
          <ShowBreakpoint breakpoint="smallTablet">
            I&apos;ll do the packing, but I&apos;m hiring a van and mover
          </ShowBreakpoint>
        </AnswerButton>
        <AnswerButton onClick={() => handleClick('full')} icon="FullService">
          <HideBreakpoint breakpoint="smallTablet">
            I&apos;ll use a packing and
            <br />
            moving service, so I&apos;ll
            <br />
            be sitting back with
            <br />a cup of tea
          </HideBreakpoint>
          <ShowBreakpoint breakpoint="smallTablet">
            I&apos;ll use a full packing service, so I&apos;ll sit back with a
            cup of tea
          </ShowBreakpoint>
        </AnswerButton>
      </AnswerContainer>
      <BackButton styleType="link" onClick={() => handleBack()}>
        Back
      </BackButton>
    </>
  );
};

MoveType.propTypes = {
  answers: PropTypes.shape({
    isSelling: PropTypes.bool,
    isBuying: PropTypes.bool,
    isFirstTimeBuyer: PropTypes.bool,
    isNotFirstTimeBuyer: PropTypes.bool,
    isOnlyHome: PropTypes.bool,
    hasMoreThanOneHome: PropTypes.bool,
    moveType: PropTypes.string,
    sellingPrice: PropTypes.number,
    buyingPrice: PropTypes.number,
    numberOfBedrooms: PropTypes.number,
    agentComission: PropTypes.number,
    depositSize: PropTypes.number,
  }),
  setAnswers: PropTypes.func,
  navigation: PropTypes.object,
};

export default MoveType;
