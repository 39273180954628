import React from 'react';
import PropTypes from 'prop-types';
import { ShowBreakpoint, HideBreakpoint, Text } from '@strikelabs/luna';
import { createEvent } from '@strikelabs/sputnik';

import {
  ProgressBar,
  AnswerContainer,
  AnswerButton,
  BackButton,
} from 'components/Pages/CostOfMoving';

const BuyerType2 = ({ answers, setAnswers, navigation }) => (
  <>
    <ProgressBar progress={answers.isSelling ? 56 : 87.5} />
    <Text>Will this purchase be a second home or an investment property?</Text>
    <AnswerContainer>
      <AnswerButton
        onClick={() => {
          setAnswers(prev => ({ ...prev, isOnlyHome: true }));
          createEvent({
            category: 'Cost of moving calculator',
            action: 'Buyer type 2 answer',
            label: 'Only home',
          });
          if (!answers.isSelling) {
            navigation.go('result');
          } else {
            navigation.go('noOfBedrooms');
          }
        }}
        icon="OnlyHome"
      >
        <HideBreakpoint breakpoint="smallTablet">
          No, this will be my
          <br />
          main residence
        </HideBreakpoint>
        <ShowBreakpoint breakpoint="smallTablet">
          No, this will be my main residence
        </ShowBreakpoint>
      </AnswerButton>
      <AnswerButton
        onClick={() => {
          setAnswers(prev => ({ ...prev, hasMoreThanOneHome: true }));
          createEvent({
            category: 'Cost of moving calculator',
            action: 'Buyer type 2 answer',
            label: 'More than one home',
          });
          if (!answers.isSelling) {
            navigation.go('result');
          } else {
            navigation.go('noOfBedrooms');
          }
        }}
        icon="SecondHome"
      >
        <HideBreakpoint breakpoint="smallTablet">
          Yes, this will be
          <br />
          my second home
          <br />
          or an investment
        </HideBreakpoint>
        <ShowBreakpoint breakpoint="smallTablet">
          Yes, this will be my second home or an investment
        </ShowBreakpoint>
      </AnswerButton>
    </AnswerContainer>
    <BackButton
      styleType="link"
      onClick={() => {
        createEvent({
          category: 'Cost of moving calculator',
          action: 'Back button click',
          label: 'From buyer type 2 to buyer type 1',
        });
        navigation.go('buyerType1');
      }}
    >
      Back
    </BackButton>
  </>
);

BuyerType2.propTypes = {
  answers: PropTypes.shape({
    isSelling: PropTypes.bool,
    isBuying: PropTypes.bool,
    isFirstTimeBuyer: PropTypes.bool,
    isNotFirstTimeBuyer: PropTypes.bool,
    isOnlyHome: PropTypes.bool,
    hasMoreThanOneHome: PropTypes.bool,
    moveType: PropTypes.string,
    sellingPrice: PropTypes.number,
    buyingPrice: PropTypes.number,
    numberOfBedrooms: PropTypes.number,
    agentComission: PropTypes.number,
    depositSize: PropTypes.number,
  }),
  setAnswers: PropTypes.func,
  navigation: PropTypes.object,
};

export default BuyerType2;
