import React from 'react';
import PropTypes from 'prop-types';

const OnlyHome = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      version="1.1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.498 1.97168C75.748 1.97168 97.0283 20.8359 97.0283 44.1082C97.0283 67.3804 75.748 86.2447 49.498 86.2447C44.2577 86.2447 39.2194 85.4892 34.5043 84.1033C29.1671 89.1012 22.2662 93.5456 13.6118 97.0324L16.4764 74.4066C7.53116 66.7421 1.97168 55.9989 1.97168 44.1042C1.97168 20.8359 23.248 1.97168 49.498 1.97168Z"
        stroke="black"
        strokeWidth="3.93931"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.248 43.9868V64.0874H67.308V45.805"
        stroke="black"
        strokeWidth="3.93931"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.355 40.1485L26.561 39.9626L26.355 39.7364L49.2959 19.252L49.4979 19.4338L49.704 19.252L72.6449 39.7364L72.4389 39.9626L72.6449 40.1485"
        stroke="black"
        strokeWidth="3.93931"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.3121 37.3809C47.1545 37.3809 49.2797 39.0697 50.1686 40.799C51.0252 39.0697 53.1261 37.3809 54.9685 37.3809C59.8129 37.3809 62.3825 44.0272 57.9664 48.3705L50.3221 55.8855L42.5203 48.3705C37.9668 44.2656 40.4678 37.3809 45.3121 37.3809Z"
        stroke="black"
        strokeWidth="3.93931"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

OnlyHome.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

OnlyHome.defaultProps = {
  width: '100',
  height: '100',
};

export default OnlyHome;
