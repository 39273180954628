import styled from 'styled-components';
import { Button } from '@strikelabs/luna';
import { rem } from 'polished';

const BackButton = styled(Button)`
  span {
    color: ${({ theme }) => theme.colors.captionText};
  }
  font-size: ${rem(14)};
`;

export default BackButton;
