export const MORTGAGE_FEES = {
  LOW: 350,
  HIGH: 2500,
  AVERAGE: 1000,
};
export const SURVEY_FEES = {
  LOW: 300,
  HIGH: 900,
};
export const CONVEYANCING_FEES = {
  LOW: 850,
  HIGH: 1500,
};
