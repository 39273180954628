import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text, Box, Flex, Button } from '@strikelabs/luna';
import { formatCurrency } from '@strikelabs/pax';
import { createEvent } from '@strikelabs/sputnik';

import { Container } from '../Incrementor';
import { ProgressBar, BackButton } from 'components/Pages/CostOfMoving';
import Slider from 'components/Slider';

const range = [
  { from: 10000, to: 100000, increment: 5000 },
  { from: 100000, to: 1000000, increment: 10000 },
  { from: 1000000, to: 3000000, increment: 50000 },
  { from: 3000000, to: 5100000, increment: 100000 },
];

const initialDepositRange = [{ from: 1000, to: 230000, increment: 1000 }];

const BuyingPrice = ({ answers, setAnswers, navigation }) => {
  const [priceCallback, setPriceCallback] = useState(230000);
  const [depositCallback, setDepositCallback] = useState(24000);
  const [depositRange, setDepositRange] = useState(initialDepositRange);

  useEffect(
    () =>
      setDepositRange([{ from: 0, to: priceCallback + 1000, increment: 1000 }]),
    [priceCallback]
  );

  const handleBack = () => {
    createEvent({
      category: 'Cost of moving calculator',
      action: 'Back button click',
      label: `From buying price to ${
        answers.isSelling ? 'selling price' : 'transaction type'
      }`,
    });
    if (answers.isSelling) {
      navigation.go('sellingPrice');
    } else {
      setAnswers({ ...answers, isBuying: false });
      navigation.go(0);
    }
  };

  return (
    <>
      <ProgressBar progress={answers.isSelling ? 42 : 50} />
      <Text>
        Tell us about the home you&apos;re hoping to buy and your deposit
        &mdash; estimates are OK!
      </Text>
      <Flex justifyContent="center">
        <Container>
          <Box>
            <Text>Purchase price</Text>
          </Box>
          <Flex justifyContent="center" mt={[2, 2, 3]}>
            <Text as="h2">{formatCurrency(priceCallback)}</Text>
          </Flex>
          <Box mt={4} mb={3}>
            <Slider
              increments={range}
              setValueCallback={setPriceCallback}
              initialSliderPos={0.185}
            />
          </Box>
          <Box>
            <Text>Deposit</Text>
          </Box>
          <Flex justifyContent="center" mt={[2, 2, 3]}>
            <Text as="h2">{formatCurrency(depositCallback)}</Text>
          </Flex>
          <Box mt={4} mb={2}>
            <Slider
              increments={depositRange}
              setValueCallback={setDepositCallback}
              initialSliderPos={0.1}
            />
          </Box>
        </Container>
      </Flex>
      <Flex mb={2} width={1} justifyContent="center">
        <Button
          onClick={() => {
            setAnswers(prev => ({
              ...prev,
              buyingPrice: priceCallback,
              depositSize: depositCallback,
            }));
            createEvent({
              category: 'Cost of moving calculator',
              action: 'Buying price answer',
              label: `Buying price: ${formatCurrency(
                priceCallback
              )}, deposit: ${formatCurrency(depositCallback)}`,
            });
            if (answers.isSelling) {
              navigation.go('buyerType2');
            } else {
              navigation.go('buyerType1');
            }
          }}
        >
          Next
        </Button>
      </Flex>

      <BackButton styleType="link" onClick={() => handleBack()} belowNext>
        Back
      </BackButton>
    </>
  );
};

BuyingPrice.propTypes = {
  answers: PropTypes.shape({
    isSelling: PropTypes.bool,
    isBuying: PropTypes.bool,
    isFirstTimeBuyer: PropTypes.bool,
    isNotFirstTimeBuyer: PropTypes.bool,
    isOnlyHome: PropTypes.bool,
    hasMoreThanOneHome: PropTypes.bool,
    moveType: PropTypes.string,
    sellingPrice: PropTypes.number,
    buyingPrice: PropTypes.number,
    numberOfBedrooms: PropTypes.number,
    agentComission: PropTypes.number,
    depositSize: PropTypes.number,
  }),
  setAnswers: PropTypes.func,
  navigation: PropTypes.object,
};

export default BuyingPrice;
