import styled from 'styled-components';
import { Text, Box } from '@strikelabs/luna';
import { rem } from 'polished';

export const Container = styled(Box)`
  padding: ${({ theme }) => theme.spacing.md};
  background: ${({ theme }) => theme.colors.backgroundLight};
  border-radius: ${rem(3)};
  border: ${rem(1)} solid ${({ theme }) => theme.colors.backgroundLight};
  text-align: center;

  ${({ theme }) => theme.media.desktop} {
    max-width: ${rem(311)};
  }
`;

export const BreakdownDescription = styled(Text)`
  font-size: ${rem(13)};
`;

export const Disclaimer = styled(BreakdownDescription)`
  color: ${({ theme }) => theme.colors.helperText};
`;
