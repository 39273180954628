import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@strikelabs/luna';
//import { createEvent } from '@strikelabs/sputnik';

import { Container, BreakdownDescription, Disclaimer } from './style';
import { BackButton } from 'components/Pages/CostOfMoving';

const Breakdown = ({
  title,
  cost,
  children,
  to,
  linkText,
  img,
  disclaimer,
  imageAlt,
}) => {
  return (
    <Container mb={2}>
      <img src={img} width="40px" alt={imageAlt} />
      <Text mt={1}>{title}</Text>
      <Text as="h3" mt={1}>
        {cost}
      </Text>
      {disclaimer && <Disclaimer mt={-0.9}>{disclaimer}</Disclaimer>}
      <BreakdownDescription my={1} styleType="caption">
        {children}
      </BreakdownDescription>
      <BackButton styleType="link" href={to} as="a">
        {linkText}
      </BackButton>
    </Container>
  );
};

Breakdown.propTypes = {
  title: PropTypes.string.isRequired,
  cost: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  linkText: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  disclaimer: PropTypes.string,
  to: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
};

Breakdown.defaultPropTypes = {
  disclaimer: null,
};
export default Breakdown;
