import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Flex,
  Box,
  ButtonAnimated,
  Underline,
  Highlight,
} from '@strikelabs/luna';
import { formatCurrency } from '@strikelabs/pax';
import { createEvent } from '@strikelabs/sputnik';

import {
  MORTGAGE_FEES,
  SURVEY_FEES,
  CONVEYANCING_FEES,
} from 'constants/cost-of-moving-calc.js';
import EstateAgentFees from './Images/EstateAgentFees.png';
import MortgageFees from './Images/MortgageFees.png';
import StampDuty from './Images/StampDuty.png';
import Surveys from './Images/Surveys.png';
import Conveyancing from './Images/Conveyancing.png';
import RemovalFees from './Images/RemovalFees.png';
import { BackButton, Breakdown } from 'components/Pages/CostOfMoving';
import { Container, BreakdownContainer, InfoText } from './style';

const Result = ({ answers, setAnswers, navigation, setFinished }) => {
  const handleRestart = () => {
    createEvent({
      category: 'Cost of moving calculator',
      action: 'Results CTA click',
      label: 'Restart calculator',
    });
    setAnswers({
      ...answers,
      isSelling: false,
      isBuying: false,
      isFirstTimeBuyer: false,
      isNotFirstTimeBuyer: false,
      isOnlyHome: false,
      hasMoreThanOneHome: false,
      moveType: '',
      sellingPrice: 0,
      buyingPrice: 0,
      numberOfBedrooms: 0,
      agentCommission: 0,
      depositSize: 0,
    });
    navigation.go(0);
  };

  const ref = useRef();

  useEffect(() => {
    ref.current.scrollIntoView();
    setFinished(true);
    createEvent({
      category: 'Cost of moving calculator',
      action: 'Page visit',
      label: 'Reached results step',
    });
  }, []);

  const [stampDuty, setStampDuty] = useState(0);
  const [removalFees, setRemovalFees] = useState(0);

  const calculateStampDuty = price => {
    if (
      answers.isFirstTimeBuyer &&
      price <= thresholds.firstTimeBuyerNoRelief
    ) {
      setStampDuty(calcFirstTimeBuyerStampDuty(price));
    } else if (
      answers.isOnlyHome ||
      (answers.isFirstTimeBuyer && price > thresholds.firstTimeBuyerNoRelief)
    ) {
      setStampDuty(calcOnlyHomeStampDuty(price));
    } else {
      setStampDuty(calcMoreThanOneHomeStampDuty(price));
    }
  };

  const thresholds = {
    firstTimeBuyerFullRelief: 425000,
    firstTimeBuyerNoRelief: 625000,
    low: 250000,
    medium: 925000,
    high: 1500000,
  };

  const calcFirstTimeBuyerStampDuty = price => {
    if (price <= thresholds.firstTimeBuyerFullRelief) {
      return 0;
    } else {
      return ((price - thresholds.firstTimeBuyerFullRelief) / 100) * 5;
    }
  };

  const calcOnlyHomeStampDuty = price => {
    if (price <= thresholds.low) {
      return 0;
    } else if (price <= thresholds.medium) {
      return ((price - thresholds.low) / 100) * 5;
    } else if (price <= thresholds.high) {
      return (
        ((price - thresholds.medium) / 100) * 10 +
        ((thresholds.medium - thresholds.low) / 100) * 5
      );
    } else {
      return (
        ((price - thresholds.high) / 100) * 12 +
        ((thresholds.high - thresholds.medium) / 100) * 10 +
        ((thresholds.medium - thresholds.low) / 100) * 5
      );
    }
  };

  const calcMoreThanOneHomeStampDuty = price => {
    if (price <= thresholds.low) {
      return (price / 100) * 3;
    } else if (price <= thresholds.medium) {
      return ((price - thresholds.low) / 100) * 8 + (thresholds.low / 100) * 3;
    } else if (price <= thresholds.high) {
      return (
        ((price - thresholds.medium) / 100) * 13 +
        ((thresholds.medium - thresholds.low) / 100) * 8 +
        (thresholds.low / 100) * 3
      );
    } else {
      return (
        ((price - thresholds.high) / 100) * 15 +
        ((thresholds.high - thresholds.medium) / 100) * 13 +
        ((thresholds.medium - thresholds.low) / 100) * 8 +
        (thresholds.low / 100) * 3
      );
    }
  };

  const calcRemovalFees = () => {
    const extraCharge = 250 + 125;
    if (answers.moveType === 'van' || answers.moveType === 'full') {
      switch (answers.numberOfBedrooms) {
        case 1:
          setRemovalFees(answers.moveType === 'van' ? 356 : 356 + extraCharge);
          break;
        case 2:
          setRemovalFees(answers.moveType === 'van' ? 576 : 576 + extraCharge);
          break;
        case 3:
          setRemovalFees(answers.moveType === 'van' ? 806 : 806 + extraCharge);
          break;
        case 4:
          setRemovalFees(
            answers.moveType === 'van' ? 1056 : 1056 + extraCharge
          );
          break;
        case 5:
          setRemovalFees(
            answers.moveType === 'van' ? 1356 : 1356 + extraCharge
          );
          break;
        default:
          return;
      }
    } else {
      setRemovalFees(250);
    }
  };

  const checkDeposit = () => {
    if (
      answers.depositSize !== answers.buyingPrice &&
      answers.depositSize !== 0
    ) {
      return true;
    }
    return false;
  };

  const lowEstimate = () => {
    return (
      answers.agentCommission +
      MORTGAGE_FEES.AVERAGE +
      stampDuty +
      SURVEY_FEES.LOW +
      CONVEYANCING_FEES.LOW +
      removalFees
    );
  };

  const highEstimate = () => {
    return (
      answers.agentCommission +
      MORTGAGE_FEES.AVERAGE +
      stampDuty +
      SURVEY_FEES.HIGH +
      CONVEYANCING_FEES.HIGH +
      removalFees
    );
  };

  useEffect(() => {
    calculateStampDuty(answers.buyingPrice);
    calcRemovalFees();
  }, []);

  return (
    <>
      <Container ref={ref} mt={1}>
        <Flex width={[1, 1, 1 / 3]} mr={5} flexDirection="column">
          <Text as="h3">That&apos;s it! Here&apos;s your total estimate:</Text>
          <Text as="h2" mt={2}>
            <Underline active weight="h2">
              {`${formatCurrency(lowEstimate())}-${formatCurrency(
                highEstimate()
              )}`}
            </Underline>
          </Text>
          <InfoText mt={2} mb={3}>
            This is your estimated cost looking at all of the big expenses that
            should make up most of your budget. Want to plan even further? Our
            full cost of moving guide will help you decide the total budget you
            should plan for.{' '}
          </InfoText>
          <ButtonAnimated to="/selling/guides/cost-of-selling-a-house">
            View our guide
          </ButtonAnimated>
          <Text as="h3" mt={5}>
            Looking to save?
          </Text>
          <InfoText mt={3} mb={4}>
            The average customer{' '}
            <Highlight active>
              <strong>saves &pound;3,450</strong>
            </Highlight>{' '}
            using Strike to sell their home for free &mdash; and we can help
            with mortgages, conveyancing and more.
          </InfoText>
          <BackButton
            styleType="link"
            onClick={() => handleRestart()}
            style={{ alignSelf: 'end' }}
          >
            Restart calculation
          </BackButton>
        </Flex>
        {/* mt={0} adds an 8px margin?? */}
        <Box width={[1, 1, 2 / 3]} mt={[5, 5, -0.1]}>
          <Text as="h3">Estimate breakdown</Text>
          <BreakdownContainer mt={3}>
            {answers.isSelling && (
              <Breakdown
                title="Estate agent fees"
                cost={formatCurrency(answers.agentCommission)}
                linkText="Book a valuation"
                to="/book-valuation"
                img={EstateAgentFees}
                imgAlt="Estate agent fees"
              >
                Estate agent fees are one of the biggest expenses when selling,
                but at Strike, we believe you should be in control of your sale.
              </Breakdown>
            )}
            {answers.isBuying && checkDeposit() && (
              <Breakdown
                title="Mortgage fees"
                cost={`${formatCurrency(MORTGAGE_FEES.AVERAGE)}`}
                linkText="Get mortgage advice"
                to="/mortgage-advice"
                img={MortgageFees}
                imgAlt="Mortgage fees"
              >
                Arranging a mortgage can be costly, but Strike offers fast,{' '}
                <strong>
                  <Underline>straighforward mortgage advice</Underline>
                </strong>{' '}
                &mdash; without the jargon or the stress.
              </Breakdown>
            )}
            {answers.isBuying && (
              <Breakdown
                title="Stamp duty cost"
                cost={`${formatCurrency(stampDuty)}`}
                linkText="Learn more"
                to="/buying/guides/stamp-duty"
                img={StampDuty}
                imgAlt="Stamp duty fees"
              >
                Stamp duty is always important to budget for &mdash; but you may
                be able to save thanks to the stamp duty holiday.
              </Breakdown>
            )}
            {answers.isBuying && (
              <Breakdown
                title="Surveys"
                cost={`${formatCurrency(SURVEY_FEES.LOW)}-${formatCurrency(
                  SURVEY_FEES.HIGH
                )}`}
                linkText="Learn more"
                to="/buying/guides/best-complete-mortgage-guide"
                img={Surveys}
                imgAlt="Survey fees"
              >
                Surveys can range from very basic to very in-depth. Confused?
                Our Moving Services Team is ready to help with free advice.
              </Breakdown>
            )}
            <Breakdown
              title="Conveyancing"
              cost={`${formatCurrency(CONVEYANCING_FEES.LOW)}-${formatCurrency(
                CONVEYANCING_FEES.HIGH
              )}`}
              linkText="Learn more"
              disclaimer="Plus disbursements"
              to="/services/conveyancing"
              img={Conveyancing}
              imgAlt="Conveyancing fees"
            >
              Conveyancing is the legal side of buying and selling. We offer a
              flat-rate service, so you can budget ahead, with no surprises.
            </Breakdown>
            {answers.isSelling && (
              <Breakdown
                title="Removal fees"
                cost={`${formatCurrency(removalFees)}`}
                linkText="Learn more"
                to="/"
                img={RemovalFees}
                imgAlt="Removal fees"
              >
                Removal costs can add up, but our Moving Services Team gives
                free advice to help you find the best rates.
              </Breakdown>
            )}
          </BreakdownContainer>
        </Box>
      </Container>
    </>
  );
};

Result.propTypes = {
  answers: PropTypes.shape({
    isSelling: PropTypes.bool,
    isBuying: PropTypes.bool,
    isFirstTimeBuyer: PropTypes.bool,
    isNotFirstTimeBuyer: PropTypes.bool,
    isOnlyHome: PropTypes.bool,
    hasMoreThanOneHome: PropTypes.bool,
    moveType: PropTypes.string,
    sellingPrice: PropTypes.number,
    buyingPrice: PropTypes.number,
    numberOfBedrooms: PropTypes.number,
    agentCommission: PropTypes.number,
    depositSize: PropTypes.number,
  }),
  setAnswers: PropTypes.func,
  navigation: PropTypes.object,
  setFinished: PropTypes.func,
};

export default Result;
