import styled from 'styled-components';
import { rem } from 'polished';

export const ProgressBarContainer = styled.div`
  height: ${rem(5)};
  width: 100%;
  background: ${({ theme }) => theme.colors.borderLight};
  border-radius: ${rem(10)};
  margin: ${({ theme }) => `${theme.spacing.md} 0`};

  ${({ theme }) => theme.media.tablet} {
    margin: ${({ theme }) => `${theme.spacing.xl} 0`};
  }
`;

export const Progress = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.accent};
  width: ${({ progress }) => `${progress}%`};
  border-radius: inherit;
`;
