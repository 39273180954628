import styled from 'styled-components';
import { Flex, Text } from '@strikelabs/luna';
import { rem } from 'polished';

export const BreakdownContainer = styled(Flex)`
  flex-flow: row wrap;
  justify-content: space-between;
`;
export const InfoText = styled(Text)`
  font-size: ${rem(16)};
`;

export const Container = styled(Flex)`
  flex-flow: column;

  ${({ theme }) => theme.media.smallTablet} {
    flex-flow: row;
  }
`;
