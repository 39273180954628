import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@strikelabs/luna';
import { createEvent } from '@strikelabs/sputnik';

import {
  ProgressBar,
  AnswerContainer,
  AnswerButton,
  BackButton,
} from 'components/Pages/CostOfMoving';

const BuyerType1 = ({ setAnswers, navigation }) => (
  <>
    <ProgressBar progress={75} />
    <Text>
      Great. Are you a first-time buyer? (Remember: If you&apos;re buying with
      someone else, then it has to be the first purchase for both of you to
      qualify as first-time buyers.)
    </Text>
    <AnswerContainer>
      <AnswerButton
        onClick={() => {
          setAnswers(prev => ({ ...prev, isFirstTimeBuyer: true }));
          createEvent({
            category: 'Cost of moving calculator',
            action: 'Buyer type 1 answer',
            label: 'First time buyer',
          });
          navigation.go('result');
        }}
        icon="FirstTimeBuyer"
      >
        Yes, I&apos;m a first-time buyer
      </AnswerButton>
      <AnswerButton
        onClick={() => {
          setAnswers(prev => ({ ...prev, isNotFirstTimeBuyer: true }));
          createEvent({
            category: 'Cost of moving calculator',
            action: 'Buyer type 1 answer',
            label: 'Not first time buyer',
          });
          navigation.go('buyerType2');
        }}
        icon="NotFirstTimeBuyer"
      >
        No, I&apos;m not a first-time buyer
      </AnswerButton>
    </AnswerContainer>
    <BackButton
      styleType="link"
      onClick={() => {
        createEvent({
          category: 'Cost of moving calculator',
          action: 'Back button click',
          label: 'From buyer type 1 to buying price',
        });
        navigation.go('buyingPrice');
      }}
    >
      Back
    </BackButton>
  </>
);

BuyerType1.propTypes = {
  setAnswers: PropTypes.func,
  navigation: PropTypes.object,
};

export default BuyerType1;
