import React, { useState, useEffect } from 'react';
import { useStep } from 'react-hooks-helper';
import Layout from 'components/Layout';
import { Container } from 'components/common';
import { Text, ShowBreakpoint } from '@strikelabs/luna';

import {
  TransactionType,
  BuyerType1,
  BuyerType2,
  SellingPrice,
  BuyingPrice,
  MoveType,
  NoOfBedrooms,
  Result,
} from 'components/Pages/CostOfMoving/Steps';

const steps = [
  { id: 'transactionType' },
  { id: 'sellingPrice' },
  { id: 'buyingPrice' },
  { id: 'buyerType1' },
  { id: 'buyerType2' },
  { id: 'moveType' },
  { id: 'noOfBedrooms' },
  { id: 'result' },
];

const onUnload = event => {
  event.returnValue = `Are you sure you want to leave? All of your progress will be lost.`;
};

const CostOfMoving = () => {
  const [answers, setAnswers] = useState({
    isSelling: false,
    isBuying: false,
    isFirstTimeBuyer: false,
    isNotFirstTimeBuyer: false,
    isOnlyHome: false,
    hasMoreThanOneHome: false,
    moveType: '',
    sellingPrice: 0,
    buyingPrice: 0,
    numberOfBedrooms: 0,
    agentCommission: 0,
    depositSize: 0,
  });

  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const [finished, setFinished] = useState(false);

  const props = {
    answers,
    setAnswers,
    navigation,
    setFinished,
  };

  useEffect(() => {
    if (finished) {
      window.removeEventListener('beforeunload', onUnload);
    } else {
      if (step.id !== 'transactionType') {
        window.addEventListener('beforeunload', onUnload);
      }
    }
  }, [step, finished]);

  const navManager = step => {
    switch (step) {
      case 'transactionType':
        return <TransactionType {...props} />;
      case 'buyerType1':
        return <BuyerType1 {...props} />;
      case 'buyerType2':
        return <BuyerType2 {...props} />;
      case 'sellingPrice':
        return <SellingPrice {...props} />;
      case 'buyingPrice':
        return <BuyingPrice {...props} />;
      case 'moveType':
        return <MoveType {...props} />;
      case 'noOfBedrooms':
        return <NoOfBedrooms {...props} />;
      case 'result':
        return <Result {...props} />;
    }
  };

  return (
    <Layout noFooter noCookieBanner noGuideLine>
      <Container>
        {step.id !== 'result' && (
          <ShowBreakpoint breakpoint="tablet">
            <Text as="h2" mt={(0, 0, 4)}>
              Cost of moving calculator
            </Text>
          </ShowBreakpoint>
        )}
        {step.id === 'transactionType' && (
          <ShowBreakpoint breakpoint="tablet">
            <Text as="p" mt={4}>
              Buying and selling a home can be expensive, but we know that it
              pays to plan ahead. Our cost of moving calculator will help you
              budget for all of the big expenses that might come your way
              &ndash; from stamp duty to removal vans, and even estate agency
              fees (though we don&apos;t think you should pay any of those).
            </Text>
          </ShowBreakpoint>
        )}
        {navManager(step.id)}
      </Container>
    </Layout>
  );
};

export default CostOfMoving;
