import styled from 'styled-components';
import { Flex } from '@strikelabs/luna';
import { rem } from 'polished';

export const Container = styled(Flex)`
  margin: ${({ theme }) => `${theme.spacing.md} 0`};
  flex-direction: ${({ noIcon }) => (noIcon ? `row` : `column`)};
  flex-wrap: ${({ noIcon }) => (noIcon ? `wrap` : `no-wrap`)};
  justify-content: ${({ noIcon }) => (noIcon ? `space-between` : `center`)};
  align-items: center;
  ${({ noIcon }) =>
    noIcon &&
    `&:after {
      content: '';
      width: ${rem(98)};
    }`}

  ${({ theme }) => theme.media.smallTablet} {
    ${({ noIcon }) =>
      noIcon &&
      `&:after {
        content: '';
        width: ${rem(200)};
      }`}
  }

  ${({ theme }) => theme.media.tablet} {
    margin: ${({ theme }) => `${theme.spacing.xl} 0`};
    flex-direction: row;
    ${({ noIcon }) =>
      noIcon &&
      `&:after {
        content: '';
        width: ${rem(272)};
        margin: ${({ theme }) => `0 ${theme.spacing.sm}`};
      }`}
  }

  ${({ theme }) => theme.media.desktop} {
    ${({ noIcon }) =>
      noIcon &&
      `&:after {
        content: '';
        width: ${rem(332)};
      }`}
  }

  ${({ theme }) => theme.media.giant} {
    ${({ noIcon }) =>
      noIcon &&
      `&:after {
        content: '';
        width: ${rem(340)};
      }`}
  }
`;
