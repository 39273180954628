import React from 'react';
import PropTypes from 'prop-types';
import { Text, ShowBreakpoint, HideBreakpoint } from '@strikelabs/luna';

import {
  JustSelling,
  JustBuying,
  BuyingAndSelling,
  FirstTimeBuyer,
  NotFirstTimeBuyer,
  OnlyHome,
  SecondHome,
  Diy,
  HiringVan,
  FullService,
} from './Icons';
import { OuterButton, InnerButton } from './style';

const ico = (size, icon) => {
  const props = {
    width: size,
    height: size,
  };
  const props2 = {
    width: size === '60' ? '48' : '100',
    height: size === '60' ? '48' : '100',
  };

  switch (icon) {
    case 'JustBuying':
      return <JustBuying {...props} />;
    case 'JustSelling':
      return <JustSelling {...props} />;
    case 'BuyingAndSelling':
      return <BuyingAndSelling {...props} />;
    case 'FirstTimeBuyer':
      return <FirstTimeBuyer {...props} />;
    case 'NotFirstTimeBuyer':
      return <NotFirstTimeBuyer {...props} />;
    case 'OnlyHome':
      return <OnlyHome {...props} />;
    case 'SecondHome':
      return <SecondHome {...props} />;
    case 'Diy':
      return <Diy {...props2} />;
    case 'Van':
      return <HiringVan {...props2} />;
    case 'FullService':
      return <FullService {...props2} />;
    default:
      return;
  }
};

const getIcon = icon => {
  return (
    <>
      <HideBreakpoint breakpoint="tablet">{ico('60', icon)}</HideBreakpoint>
      <ShowBreakpoint breakpoint="tablet">{ico('100', icon)}</ShowBreakpoint>
    </>
  );
};

const AnswerButton = ({ onClick, children, icon, noIcon }) => {
  return (
    <>
      <OuterButton
        noIcon={noIcon}
        styleType="ghost"
        onClick={onClick}
        fullWidth
      >
        <InnerButton noIcon={noIcon}>
          {getIcon(icon)}
          <Text textAlign="center">{children}</Text>
        </InnerButton>
      </OuterButton>
    </>
  );
};

AnswerButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  noIcon: PropTypes.bool,
};

AnswerButton.defaultProps = {
  icon: null,
  noIcon: false,
};

export default AnswerButton;
