import React from 'react';
import PropTypes from 'prop-types';

const NotFirstTimeBuyer = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      version="1.1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64.2283 50.4746C60.2474 53.0652 55.5268 54.5647 50.4587 54.5647"
        stroke="black"
        strokeWidth="3.94051"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.1003 47.6539C27.9796 42.8485 24.7666 35.9415 24.7666 28.2666C24.7666 13.7413 36.2688 1.96826 50.4587 1.96826C64.6486 1.96826 76.1508 13.7413 76.1508 28.2666C76.1508 32.5426 75.1526 36.5801 73.3824 40.1528"
        stroke="black"
        strokeWidth="3.94051"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.5655 56.1372L67.6516 62.4946L73.8796 72.655L83.7976 66.2936L77.5655 56.1372Z"
        stroke="black"
        strokeWidth="3.94051"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.8649 69.5672L70.056 86.6831L98.363 67.3241L88.1823 51.7521C93.9092 48.1066 93.6869 44.5622 89.1967 43.863L58.772 39.1465L58.7033 39.4173L58.4325 39.3769L50.7737 69.8622C49.8806 75.5083 56.1167 70.6099 58.8649 69.5672Z"
        stroke="black"
        strokeWidth="3.94051"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.511 42.3232C17.7425 34.3613 28.6385 32.0617 37.8492 37.1864C47.0599 42.311 51.0974 52.9241 46.8659 60.886C43.5559 67.118 36.1599 69.8825 28.687 68.3305C24.0999 76.9633 19.5572 85.4303 14.9094 94.0347L4.92274 97.0578L1.97241 86.8367L7.06072 85.2969L7.18197 85.0706L5.7068 79.9661L10.6092 78.6203L10.7143 78.4263L9.27144 73.4229L14.1536 71.9396L18.7165 63.3473C12.2864 57.7497 9.92213 49.0806 13.511 42.3232Z"
        stroke="black"
        strokeWidth="3.94051"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.326 45.168C31.8555 44.1738 33.072 43.806 34.046 44.3476C35.02 44.8892 35.3797 46.134 34.8503 47.1322C34.3208 48.1264 33.1043 48.4942 32.1303 47.9527C31.1563 47.4111 30.7966 46.1663 31.326 45.168Z"
        stroke="black"
        strokeWidth="3.94051"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.9269 49.3756C63.4563 48.3813 64.6728 48.0136 65.6468 48.5551C66.6208 49.0967 66.9805 50.3415 66.4511 51.3397C65.9217 52.334 64.7051 52.7017 63.7311 52.1602C62.7571 51.6186 62.3974 50.3698 62.9269 49.3756Z"
        stroke="black"
        strokeWidth="3.94051"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

NotFirstTimeBuyer.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

NotFirstTimeBuyer.defaultProps = {
  width: '100',
  height: '100',
};

export default NotFirstTimeBuyer;
