import React from 'react';
import { Container } from './style';
import PropTypes from 'prop-types';

const AnswerContainer = ({ children, noIcon }) => {
  return <Container noIcon={noIcon}>{children}</Container>;
};

AnswerContainer.propTypes = {
  children: PropTypes.node.isRequired,
  noIcon: PropTypes.bool,
};

AnswerContainer.defaultProps = {
  noIcon: false,
};

export default AnswerContainer;
