import React from 'react';
import PropTypes from 'prop-types';
import { Text, ShowBreakpoint, HideBreakpoint } from '@strikelabs/luna';
import { createEvent } from '@strikelabs/sputnik';

import {
  ProgressBar,
  AnswerContainer,
  AnswerButton,
} from 'components/Pages/CostOfMoving';

const TransactionType = ({ setAnswers, navigation }) => {
  return (
    <>
      <ProgressBar progress={20} />
      <Text>
        Let&apos;s get started with a little about your moving journey.
      </Text>
      <AnswerContainer>
        <AnswerButton
          onClick={() => {
            setAnswers(prev => ({ ...prev, isBuying: true }));
            createEvent({
              category: 'Cost of moving calculator',
              action: 'Transaction type answer',
              label: 'Just buying',
            });
            navigation.go('buyingPrice');
          }}
          icon="JustBuying"
        >
          I&apos;m just buying
        </AnswerButton>
        <AnswerButton
          onClick={() => {
            setAnswers(prev => ({ ...prev, isSelling: true }));
            createEvent({
              category: 'Cost of moving calculator',
              action: 'Transaction type answer',
              label: 'Just selling',
            });
            navigation.go('sellingPrice');
          }}
          icon="JustSelling"
        >
          I&apos;m just selling
        </AnswerButton>
        <AnswerButton
          onClick={() => {
            setAnswers(prev => ({ ...prev, isBuying: true, isSelling: true }));
            createEvent({
              category: 'Cost of moving calculator',
              action: 'Transaction type answer',
              label: 'Buying and selling',
            });
            navigation.go('sellingPrice');
          }}
          icon="BuyingAndSelling"
        >
          <HideBreakpoint breakpoint="smallTablet">
            I&apos;m buying
            <br />
            and selling
          </HideBreakpoint>
          <ShowBreakpoint breakpoint="smallTablet">
            I&apos;m buying and selling
          </ShowBreakpoint>
        </AnswerButton>
      </AnswerContainer>
    </>
  );
};

TransactionType.propTypes = {
  setAnswers: PropTypes.func,
  navigation: PropTypes.object,
};

export default TransactionType;
